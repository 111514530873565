<template>
  <div class="flip-card">
    <div class="flip-card-inner relative bg-cover bg-center">
      <div class="flip-card-front background-image rounded-3xl w-full h-full absolute flex flex-col justify-between p-4">
        <img class="absolute top-0 right-0 w-8 rounded-lg m-3" :src="image">
        <p class="absolute bottom-0 left-0 text-lg md:text-xl font-extrabold text-white m-4 text-left">{{ title }}</p>
      </div>
      <div
        class="flip-card-back rounded-2xl border-2 border-customBlue p-4 bg-white w-full h-full absolute text-center items-center flex flex-col overflow-hidden">
        <div class="description-content text-sm" :class="{ 'expanded': showFullDescription }">
          {{ description }}
        </div>
        <v-btn class="block text-xs m-2" @click="showModal">Read More</v-btn>
        <v-dialog v-model="modalVisible" max-width="600">
          <v-card>
            <v-card-title>{{ title }}</v-card-title>
            <v-card-text>
              {{ description }}
            </v-card-text>
            <v-card-actions>
              <v-btn @click="hideModal">Close</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PhilosophyCard",
  props: {
    icon: String,
    title: String,
    description: String,
  },
  data() {
    return {
      image: require("../../public/assets/images/28C-asterix.svg"),
      showFullDescription: false,
      modalVisible: false,
    };
  },
  methods: {
    toggleDescription() {
      this.showFullDescription = !this.showFullDescription;
    },
    showModal() {
      this.modalVisible = true;
    },
    hideModal() {
      this.modalVisible = false;
    },
  },
};
</script>


<style scoped>
.background-image {
  background-image: url(/public/assets/images/philosophyCard.jpg);
  background-size: cover;
  background-position: center;
}

.flip-card {
  background-color: transparent;
  width: 100%;
  height: 100%;
  perspective: 1000px;
  /* Remove this if you don't want the 3D effect */
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-front {
  background-color: #bbb;
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.flip-card-back {
  background-color: dodgerblue;
  color: white;
  transform: rotateY(180deg);
}

.description-content {
  max-height: 100%;
  /* Set the maximum height to prevent overflow */
  overflow: hidden;
  /* Hide overflow content */
}
</style>
