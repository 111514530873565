import { createApp } from "vue";
import App from "./App.vue";
import VueSmoothScroll from "vue3-smooth-scroll";
import VueGtag from "vue-gtag";

// FONTAWESOME
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faMapMarkerAlt, faEnvelope } from "@fortawesome/free-solid-svg-icons";

// VUETIFY
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import "vuetify/styles";

import "./index.css";
import "@mdi/font/css/materialdesignicons.css";
import "material-design-icons-iconfont/dist/material-design-icons.css";

library.add(faMapMarkerAlt, faEnvelope);

const vuetify = createVuetify({
  components,
  directives,
});

const app = createApp(App);

app.use(VueSmoothScroll);
app.use(vuetify);
app.use(VueGtag, {
  config: { id: "G-722JGTR9TK" },
});

app.component("font-awesome-icon", FontAwesomeIcon);

app.mount("#app");
