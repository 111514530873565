// useSectionStyles.js
import { ref, onMounted, onBeforeUnmount } from "vue";

export const useSectionStyles = () => {
  const currentSection = ref("slogan");
  const isHeaderNotInIntialPosition = ref(true);
  // const setSectionHeight = () => {
  //   const windowHeight = window.innerHeight;
  //   const sections = document.querySelectorAll("section");

  //   sections.forEach((section) => {
  //     section.style.height = `${windowHeight}px`;
  //   });
  // };

  const handleScroll = () => {
    const scrollPosition = window.scrollY;
    const sections = document.querySelectorAll("section");
    let currentSectionId = null;

    sections.forEach((section) => {
      const sectionTop = section.offsetTop;
      const sectionBottom = sectionTop + section.clientHeight;

      if (scrollPosition >= sectionTop && scrollPosition < sectionBottom) {
        currentSectionId = section.id;
      }
    });

    if (currentSectionId) {
      currentSection.value = currentSectionId;
    }
    if (window.scrollY > 20) {
      isHeaderNotInIntialPosition.value = false;
    } else {
      isHeaderNotInIntialPosition.value = true;
    }
  };

  onMounted(() => {
    // setSectionHeight();
    // window.addEventListener("resize", setSectionHeight);
    window.addEventListener("scroll", handleScroll);
  });

  onBeforeUnmount(() => {
    // window.removeEventListener("resize", setSectionHeight);
    window.removeEventListener("scroll", handleScroll);
  });

  return { currentSection, isHeaderNotInIntialPosition };
};
