<template>
    <section id="quotes" class="flex py-24 lg:py-32 justify-center">
        <div class="max-w-7xl px-6 md:px-0">
            <div class="flex flex-col lg:flex-row items-center lg:items-start justify-evenly w-full">
                <div
                    class="w-2/3 md:w-1/4 lg:w-1/4 p-2 md:p-3 lg:p-4 text-center border-t-4 md:border-t-6 lg:border-t-8 border-customBlue">
                    <h3 class="text-base md:text-2xl  text-customBlack font-bold mb-2">Under Management</h3>
                    <p class="text-customBlue font-extrabold text-5xl lg:text-7xl">$500M</p>
                </div>

                <div
                    class="w-2/3 md:w-1/4 lg:w-1/4  p-2 md:p-3 lg:p-4 text-center border-t-4 md:border-t-6 lg:border-t-8 border-customBlue">
                    <h3 class="text-base md:text-2xl text-customBlack font-bold mb-2">Industry Sectors</h3>
                    <p class="text-customBlue font-extrabold text-5xl lg:text-7xl">x6</p>
                </div>

                <div
                    class="w-2/3 md:w-1/4 lg:w-1/4  p-2 md:p-3 lg:p-4 text-center border-t-4 md:border-t-6 lg:border-t-8 border-customBlue">
                    <h3 class="text-base md:text-2xl text-customBlack font-bold mb-2">Investments</h3>
                    <p class="text-customBlue font-extrabold text-5xl lg:text-7xl">x12</p>
                </div>
            </div>
            <div class="w-full">

                <div class="px-2 md:px-3 lg:px-4 text-center text-base md:text-2xl lg:text-3xl relative">
                    <span
                        class="opening-quote text-customBlue font-extrabold  text-3xl md:text-7xl lg:text-9xl absolute top-0 left-0">&#8220;</span>
                    <span
                        class="closing-quote text-customBlue font-extrabold  text-3xl md:text-7xl lg:text-9xl absolute bottom-0 right-0 ">&#8221;</span>
                    <p class="p-2 md:p-8 lg:p-10 italic">
                        The key success of any society lies in its investments, and the
                        ability to believe in a fresh market and get the best out of it. At
                        Twentyeightcapital we proudly embrace this mindset with our
                        partners, offering operational expertise, industry insights, and
                        strategic capital to help the inspired translate ambitions into reality.
                    </p>
                </div>

                <div class="text-right text-sm md:text-base lg:text-xl">
                    <span>Mohamed Yassin Al Shokeiry. CEO, twentyeightcapital</span>
                </div>

            </div>
        </div>
    </section>
</template>
    
<script>
export default {
    name: "QuotesSection",
};
</script>
    
<style scoped></style>
    