<template>
  <footer class="w-full text-black text-center">
    <!-- <div v-if="showScrollButton" class="mb-4">
      <svg class="animate-bounce w-10 h-10 text-customBlack text-lg font-semibold m-auto"
        xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 14l-7 7m0 0l-7-7m7 7V3" />
      </svg>
    </div> -->
    <div class="py-4 px-6">
      ©2023 TwentyEightCapital Ltd. All Rights Reserved.
    </div>
  </footer>
</template>

<script>
export default {
  name: "FooterSection",
  data() {
    return {
      showScrollButton: true,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      // Adjust the threshold value as needed
      const threshold = 100; // Pixels from the bottom to trigger the change
      const scrollHeight = window.scrollY + window.innerHeight;
      const totalHeight = document.body.scrollHeight;

      if (totalHeight - scrollHeight < threshold) {
        this.showScrollButton = false;
      } else {
        this.showScrollButton = true;
      }
    },
  },
};
</script>

<style scoped>
/* Add your custom styles for the footer here */
</style>
