<template>
  <section id="contact" class="py-24 lg:py-32 background-image">

    <!-- <div class="w-full">
      <img :src="contactImageUrl" alt="Team Member"
        class="rounded-lg w-[300px] md:w-[400px] lg:w-full h-[300px] mx-auto md:h-[400px] lg:max-h-[450px] xl:max-h-[600px]" />
    </div> -->


    <div class="max-w-7xl px-6 md:px-0 flex mx-auto">

      <div class="grid grid-cols-1 sm:gap-x-16 gap-y-6 sm:gap-y-16 lg:max-w-none sm:grid-cols-2 ">

        <div>
          <img :src="contactImageUrl" alt="Team Member" class="rounded-lg w-full" />
        </div>

        <div>

          <h1 class="text-3xl md:text-6xl font-bold text-white mb-9">
            Contact
          </h1>

          <div class="flex items-start">

            <font-awesome-icon style="color: #213F9A !important" icon="map-marker-alt"
              class="rounded-full w-6 h-6 md:w-8 lg:w-10 md:h-8 lg:h-10 p-2 md:p-3 lg:p-4 bg-white mr-2" />

            <span class="text-base text-white">
              <h5 class="text-white uppercase text-base md:text-xl lg:text-2xl font-bold">
                Global Hq
              </h5>
              <p class="text-white text-base md:text-xl lg:text-2xl mt-1 md:mt-2">
                Al Maqam Tower
              </p>
              <p class="text-white text-base md:text-xl lg:text-2xl mt-1 md:mt-2">
                Al Maryah Island
              </p>
              <p class="text-white text-base md:text-xl lg:text-2xl mt-1 md:mt-2">
                Abu Dhabi
              </p>
              <p class="text-white text-base md:text-xl lg:text-2xl mt-1 md:mt-2">
                United Arab Emirates
              </p>
            </span>

          </div>

          <div class="flex items-start mt-9">

            <font-awesome-icon style="color: #213F9A !important" icon="envelope"
              class="rounded-full w-6 h-6 md:w-8 lg:w-10 md:h-8 lg:h-10 p-2 md:p-3 lg:p-4 bg-white mr-2" />

            <span class="text-base text-white">
              <h5 class="text-white uppercase text-base md:text-xl lg:text-2xl font-bold mt-1 md:mt-2">
                Email
              </h5>
              <p class="text-white text-base md:text-xl lg:text-2xl xl:text-3xlmt-1 md:mt-2 overflow-hidden break-words">
                <a href="mailto:hello@twentyeightcapital.com">hello@twentyeightcapital.com</a>
              </p>
            </span>

          </div>

        </div>

      </div>



    </div>


  </section>
</template>

<script>
export default {
  name: "ContactSection",
  computed: {
    contactImageUrl() {
      return require("@/../public/assets/images/contact.jpg");
    },
  },
};
</script>

<style scoped>
.background-image {
  background-image: url(/public/assets/images/slogan.jpg);
  background-size: cover;
  background-position: center;
}
</style>
