
<template>
  <section id="slogan" class="min-h-screen flex flex-col py-24 lg:py-32  items-center justify-center background-image">
    <div class="text-white max-w-7xl px-6 md:px-0">
      <h1 class="text-3xl md:text-6xl lg:text-7xl font-bold " style="text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.7);">
        Proud partners of inspired Founders transforming the way we live
      </h1>
      <p class="text-2xl md:text-3xl lg:text-4xl mt-4 leading-normal ">
        We are a long-standing family fund focused on early-stage investments.
        We have a passion for innovation and positive societal change and have a
        proud history of delivering exemplary stakeholder returns
      </p>
    </div>

    <button @click="scrollToQuotes"
      class="bg-yellow-300 text-black font-semibold uppercase px-6 py-3 rounded-full mt-6 sm:mt-9 text-sm lg:text-lg hover:bg-yellow-500 transition-colors">
      Learn More
    </button>

  </section>
</template>
  
<script>
export default {
  name: "SloganSection",
  methods: {
    scrollToQuotes() {
      document.getElementById('quotes').scrollIntoView({ behavior: "smooth" })
    }
  }
};
</script>
  
<style scoped>
.background-image {
  background-image: url(/public/assets/images/slogan.jpg);
  background-size: cover;
  background-position: center;
}
</style>
  