<template>
  <v-app>
    <Header :currentSection="currentSection" :isHeaderNotInIntialPosition="isHeaderNotInIntialPosition" />

    <Slogan />
    <Quotes />
    <Philosophy />
    <PhilosophyCards />
    <Portfolio />
    <Team />
    <Contact />

    <Footer />


  </v-app>
</template>

<script>
import Header from "@/components/Header.vue";
import Slogan from "./views/Slogan.vue";
import Quotes from "./views/Quotes.vue";
import Philosophy from "@/views/Philosophy.vue";
import PhilosophyCards from "./views/PhilosophyCards.vue";
import Portfolio from "@/views/Portfolio.vue";
import Team from "@/views/Team.vue";
import Contact from "@/views/Contact.vue";
import Footer from "@/components/Footer.vue";

import { useSectionStyles } from '@/composables/useSectionStyles';


export default {
  setup() {
    const { currentSection, isHeaderNotInIntialPosition } = useSectionStyles();
    return { currentSection, isHeaderNotInIntialPosition };

  },
  components: {
    Header,
    Slogan,
    Quotes,
    Philosophy,
    PhilosophyCards,
    Portfolio,
    Team,
    Contact,
    Footer,
  },
};
</script>

<style scoped></style>