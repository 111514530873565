<template>
  <section id="portfolio" class="py-24 lg:py-32 bg-gray-100 ">

    <div class="max-w-7xl px-6 md:px-0 mx-auto">


      <h1 class="text-3xl md:text-6xl font-bold text-customBlue mb-9">Portfolio
      </h1>


      <div class="grid grid-cols-1 gap-0.5 overflow-hidden sm:mx-0 sm:grid-cols-2 md:grid-cols-3">

        <div v-for="company in companies" :key="company" class="bg-white p-8 sm:p-10 cursor-pointer"
          @click="company.expanded = !company.expanded">

          <div class="mx-auto w-3/4">
            <img class="h-20 w-full object-contain grayscale" :src="company.imageSource" />
          </div>

          <div v-if="company.expanded">

            <p class="mt-6 text-base leading-8">{{ company.paragraphText }}</p>

            <!-- <dl class="mt-6 grid grid-cols-2 gap-0.5 overflow-hidden rounded-2xl text-center bg-gray-100">

              <div v-for="stat in company.stats" :key="stat" class="flex flex-col p-3 bg-gray-200">
                <dt class="text-sm font-semibold leading-6 text-gray-600">{{ stat.name }}</dt>
                <dd class="order-first text-2xl font-semibold tracking-tight text-black">{{ stat.value }}</dd>
              </div>

            </dl> -->

            <div class="mt-6 text-customBlue">
              <a :href="company.externalLink" target="_blank">Visit {{ company.externalLinktitle }}</a>
            </div>

          </div>

        </div>

      </div>

    </div>

  </section>
</template>

<script>
export default {
  name: "PortfolioSection",
  data() {
    return {
      companies: [{
        imageSource: require("/public/assets/logos/procare.png"),
        externalLink: "https://www.procareeg.com/",
        externalLinktitle: "Procare",
        paragraphText: "Procare, Medical and Dental Commerce, was incorporated in 2015 and is based in Cairo, Egypt. Today, Procare is an industry-leading specialty distributor serving the Dental & Medical market.",
        stats: [
          { name: "YoY Growth", value: "36%" },
          { name: "IRR", value: "15%" }
        ],
        expanded: false,
      },
      {

        imageSource: require("/public/assets/logos/lycheeegypt.png"),
        externalLink: "https://www.lycheeegypt.com/",
        externalLinktitle: "Lychee",
        paragraphText: "Lychee started with a blender and a passion for making healthy a lifestyle. It’s now Egypt’s most successful healthy eating business. From farm to fork, Lychee delivers the highest quality of products to lead healthy lifestyles.",
        stats: [
          { name: "YoY Growth", value: "124%" },
          { name: "IRR", value: "35%" }
        ],
        expanded: false,
      },
      {
        imageSource: require("/public/assets/logos/quicrete.png"),
        externalLink: "https://www.quicrete.com/",
        externalLinktitle: "Quicrete",
        paragraphText: "The leading force in the high-tech and unique ready-mix cement solutions in North Africa and the Middle East.",
        stats: [
          { name: "YoY Growth", value: "56%" },
          { name: "IRR", value: "26.8%" }
        ],
        expanded: false,
      },
      {
        imageSource: require("/public/assets/logos/HKGroup.png"),
        externalLink: "https://holdenknight.com/",
        externalLinktitle: "Holden Knight",
        paragraphText: "Holden Knight proudly represents a platform of funding and expertise to help entrepreneurs achieve their dreams. With deep specialist expertise in Healthcare, Education and Medicine, the group operates Globally and is trusted by leading brands from all over the World.",
        stats: [
          { name: "YoY Growth", value: "TBC" },
          { name: "IRR", value: "TBC" }
        ],
        expanded: false,
      },
      {
        imageSource: require("/public/assets/logos/hndb.png"),
        externalLink: "https://www.hdb-egy.com/en/",
        externalLinktitle: "H&D Bank",
        paragraphText: "With a market cap of $14 billion, H&D Bank is one of the leading financial institutions in MENA. For more than 40 years, H&D bank has proudly led accessibility and modernisation of banking services to individuals and companies across the region.",
        stats: [
          { name: "YoY Growth", value: "27%" },
          { name: "IRR", value: "53.30%" }
        ],
        expanded: false,
      }, {
        imageSource: require("/public/assets/logos/zencare.png"),
        externalLink: "https://zencare.uk/about-us",
        externalLinktitle: "Zen Care",
        paragraphText: "Zen care and education offers a complete individual tailored package of residential Child care, therapeutic parenting and needs led educational packages.",
        stats: [
          { name: "YoY Growth", value: "Start-up" },
          { name: "IRR", value: "Start-up" }
        ],
        expanded: false,
      },
      {
        imageSource: require("/public/assets/logos/p1ventures.jpg"),
        externalLink: "https://www.p1.ventures/",
        externalLinktitle: "P1 Ventures",
        paragraphText: "Founded in 2020 by Mikael Hajjar and Hisham Halbouny, P1 Ventures is an early-stage VC fund that focuses on African founders and companies building software businesses with regional and global potential. P1 Ventures works alongside African founders across fintech, e-commerce, healthtech, SaaS and AI.",
        stats: [
          { name: "YoY Growth", value: "Start-up" },
          { name: "IRR", value: "Start-up" }
        ],
        expanded: false,
      },
      {
        imageSource: require("/public/assets/logos/saqaya.png"),
        externalLink: "https://saqaya.com/",
        externalLinktitle: "Saqaya",
        paragraphText: "SAQAYA offers best-in-class nearshore development services led by a UK-based professional services team with years of Global consultancy experience. We are proud to have pioneered tech innovation for some of the largest brands in the World.",
        stats: [
          { name: "YoY Growth", value: "Start-up" },
          { name: "IRR", value: "Start-up" }
        ],
        expanded: false,
      },
      ],
    };
  },
};
</script>
  
<style scoped>
.background-image {
  background-image: url(/public/assets/images/portfolio.jpg);
  background-size: cover;
  background-position: center;
}

/* Ensure images don't exceed the container */
.v-carousel-item img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.text-left {
  max-height: 100%;
  overflow: hidden;
}
</style>