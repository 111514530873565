<template>
  <header :class="isHeaderNotInIntialPosition ? 'bg-transparent' : 'backdrop-blur-xl bg-white/50'"
    class="fixed top-0 w-full z-10">
    <div class="flex justify-center">
      <div class="w-full max-w-7xl px-6 md:px-0">
        <div class="flex justify-between">
          <div class="py-6">
            <img :src="logoSource" alt="Logo" @click="scrollToSection('slogan')" class="h-12 sm:h-16" />
          </div>
          <nav class="hidden md:flex md:space-x-9 items-center">
            <a :class="{
              'selected-link': Boolean(currentSection.includes('philosophy')),
              'navItem': !isHeaderNotInIntialPosition,
              'navItemFixedHeader': isHeaderNotInIntialPosition
            }" href="#philosophy">
              Philosophy
            </a>
            <a :class="{
              'selected-link': Boolean(currentSection === 'portfolio'),
              'navItem': !isHeaderNotInIntialPosition,
              'navItemFixedHeader': isHeaderNotInIntialPosition

            }" href="#portfolio">
              Portfolio
            </a>
            <a :class="{
              'selected-link': Boolean(currentSection === 'team'),
              'navItem': !isHeaderNotInIntialPosition,
              'navItemFixedHeader': isHeaderNotInIntialPosition

            }" href="#team">
              Team
            </a>
            <a :class="{
              'selected-link': Boolean(currentSection === 'contact'),
              'navItem': !isHeaderNotInIntialPosition,
              'navItemFixedHeader': isHeaderNotInIntialPosition

            }" href="#contact">
              Contact
            </a>
          </nav>
          <v-menu class="items-center" open-on-click>
            <template v-slot:activator="{ props }">
              <div class=" md:hidden  flex justify-center self-center">
                <v-btn icon="mdi-menu" :elevation="0"
                  :class="isHeaderNotInIntialPosition ? 'text-white navItem ' : 'text-customBlack navItem'"
                  v-bind="props">

                </v-btn>
              </div>
            </template>

            <v-list>
              <v-list-item v-for="(item, index) in items" :key="index">
                <v-list-item-title @click="scrollToSection(item.title.toLowerCase())">{{ item.title }}</v-list-item-title>

              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: "AppHeader",
  computed: {
    logoSource() {
      return this.isHeaderNotInIntialPosition ?
        require('@/../public/assets/logos/white_logo.png')
        : require('@/../public/assets/logos/blue_logo.png')
    },
  },
  methods: {
    scrollToSection: (section) => {
      document.getElementById(section).scrollIntoView({ behavior: "smooth" })
    }

  },
  data: () => ({
    items: [
      { title: 'Philosophy' },
      { title: 'Portfolio' },
      { title: 'Team' },
      { title: 'Contact' },
    ],
  }),
  props: {
    currentSection: String,
    isHeaderNotInIntialPosition: Boolean,
  },
};
</script>

<style scoped>
.selected-link {
  border-bottom: 4px solid #20409a;
}

.navItemFixedHeader {
  color: white;
  font-size: large;
  text-transform: uppercase;
  font-weight: bolder;

  @media (max-width: 1200px) {
    font-size: large;
  }
}

.navItem {
  color: #060808;
  font-size: large;
  text-transform: uppercase;
  font-weight: bolder;

  @media (max-width: 1200px) {
    font-size: large;
  }
}
</style>
