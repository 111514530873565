
<template>
    <section id="philosophy_cards" class="flex py-24 lg:py-32 justify-center">

        <div class="max-w-7xl px-6 md:px-0 ">


            <!--w-1/2 lg:w-1/3 h-1/3 lg:h-1/2 -->

            <!-- <div v-for="(item, index) in items" :key="index"> -->

            <!-- <Card :title="item.title" :description="item.description" /> -->

            <!-- <h1>{{ item.title }}</h1>

            </div> -->


            <div class="grid grid-cols-2 gap-x-6 gap-y-6 lg:grid-cols-3">

                <div v-for="item in items" :key="item" class="h-48 w-40 sm:h-48 sm:w-48">
                    <Card :title="item.title" :description="item.description" />
                </div>

            </div>



        </div>

    </section>
</template>
    
<script>
import Card from '@/components/PhilosophyCard.vue';
export default {
    name: "PhilosophyCardsSection",
    components: {
        Card,
    },
    data() {
        return {
            items: [
                {
                    title: 'Pre-Seed > Seed > Series A',
                    description: 'Beyond investment capital, we support our Founders at every phase of the investment cycle. This includes offering guidance, advice, and mentorship during Pre-Seed, aiding in refining business models and making network introductions at the Seed stage, and assisting in scaling strategies and geographical expansion during Series A',
                },
                {
                    title: 'Valuations',
                    description: 'We utilise valuation methodologies and industry benchmarks combined with deep financial expertise and due diligence to establish an accurate valuation of your company, helping you understand the rationale behind the valuation and the factors that drive it. We complement this approach with strategic guidance to help you realize your company’s full potential.',
                },
                {
                    title: 'Geographic Expansion',
                    description: 'We have extensive experience in supporting our Founders to scale into new markets. Our support extends beyond capital into a wide range of advisory services enabling you to scale at pace. Our services include market analysis, GTM strategies, company formation, proposition localization, operations and strategic partnerships.',
                },
                {
                    title: 'Legal Support',
                    description: 'Our legal team have deep experience working with early-stage companies and is uniquely positioned to support you with a range of legal matters, including compliance, contracts, IP protection, risk management, corporate governance and regulatory guidance.',
                },
                {
                    title: 'Operational Support',
                    description: 'We actively support our Founders to enhance overall efficiency and effectiveness within the company, advising on strategy, industry best practices, facilitating industry connections, aiding in talent acquisition, optimizing processes, and guiding financial planning and compliance',
                },
                {
                    title: 'Fundraise / Fundraising',
                    description: "We actively support fundraising by connecting our Founders and their companies to potential investors, guiding pitch preparation, assisting in due diligence, offering strategic advice, and adding credibility to the process. We provide mentorship, negotiation support, and leverage our reputation to enhance the company's credibility and attract additional investors",
                },
            ],
        };
    },
};
</script>
    
<style scoped></style>
    