
<template>
  <section id="philosophy" class="flex flex-col py-24 lg:py-32 items-center justify-center background-image">
    <div class="max-w-7xl px-6 md:px-0">
      <div class=" text-white">
        <h1 class="text-3xl md:text-6xl  lg:text-7xl font-bold" style="text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.7);">
          Philosophy
        </h1>
        <p class="text-2xl md:text-4xl lg:text-5xl font-bold mt-4 leading-normal md:leading-normal lg:leading-normal"
          style="text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.7);">
          We focus our investments in Companies who are pioneering innovation, transforming the lives of Consumers and
          promoting positive societal change
        </p>
      </div>

    </div>

  </section>
</template>
  
<script>
export default {
  name: "PhilosophySection",
};
</script>
  
<style scoped>
.background-image {
  background-image: url(/public/assets/images/philosophy.jpg);
  background-size: cover;
  background-position: center;
}
</style>
  