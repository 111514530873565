<template>
  <section id="team" class="py-24 lg:py-32">

    <div class="max-w-7xl px-6 md:px-0 mx-auto">

      <div class="grid grid-cols-1 sm:gap-x-16 gap-y-6 sm:gap-y-16 lg:max-w-none sm:grid-cols-2">

        <div class="lg:mb-0">

          <h1 class="text-3xl md:text-6xl font-bold text-customBlue mb-9">Our
            principles make us what we are</h1>

          <ul class="list-item">
            <li v-for="(item, index) in listItems" :key="index" class="flex">
              <img class="rounded-lg h-8 mr-3" :src="asterixImage" alt="Asterix">
              <div class="text-customBlack text-lg  sm:text-xl lg:text-2xl">{{ item }}</div>
            </li>
          </ul>

        </div>

        <div class="w-full">

          <ul role="list" class="sm:-mt-12 space-y-12 divide-y divide-gray-200 xl:col-span-3">
            <li v-for="person in people" :key="person" class="flex flex-col sm:flex-row gap-10 pt-12">
              <img class="aspect-[4/5] w-52 flex-none rounded-2xl object-cover grayscale" :src="person.imgSrc" />
              <div class="max-w-xl flex-auto">
                <h3 class="text-lg font-semibold leading-8 tracking-tight text-gray-900">{{ person.title }}</h3>
                <p class="text-base leading-7 text-gray-600">{{ person.subtitle }}</p>
                <p class="mt-6 text-base leading-7 text-gray-600">{{ person.bio }}</p>
              </div>
            </li>
          </ul>

        </div>

      </div>

    </div>

  </section>
</template>

<script>
export default {
  name: "TeamSection",
  data() {
    return {
      people: [
        {
          imgSrc: require("@/../public/assets/images/yassin.jpeg"),
          description: "Mohamed Bio's Text",
          title: "Mohamed Yassin Al Shokeiry",
          subtitle: "Founder & CEO",
          bio: 'Founder & CEO of Twenty Eight Capital, dedicated to nurturing startups and businesses. An investor that empowers entrepreneurs, with a proven track record of scaling companies.',

        },
        {
          imgSrc: require("@/../public/assets/images/waseem.jpeg"),
          description: "Waseem Bio's Text",
          title: "Waseem Al Zaro",
          subtitle: "Chief Financial Officer",
          bio: 'Waseem is responsible for the day to day management, leading sourcing & origination, fundraising, screening, execution and exits. He is a Licensed CFA and holds several advisory roles for clients including sovereign wealth funds, PE firms and family offices.',
        }
      ],
      asterixImage: require("../../public/assets/images/28C-asterix.svg"),
      listItems: [
        "One-team culture and decision making",
        "Collaborative decision-making processes",
        "Shared purpose, values and long-term vision",
        "Diverse perspectives from industry, service and investing",
        "Leadership engagement throughout the investment process"
      ]
    };
  }
};
</script>

<style scoped>
.team-section {
  background-color: #f7f7f7;
}
</style>
